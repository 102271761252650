'use strict'

import mainCSS from '../css/sass/main.scss';

import parallaxImage from './modules/parallaxImage';
import animation from './modules/animations';
import mobileMenu from './modules/mobileMenu';
import placeholderForm from './modules/placeholderForm';
import oqueOferecemos from './modules/oqueOferecemos';
import targetScroll from './modules/targetScroll';
import scrollTop from './modules/scrollTop';
import sliderOferecemos from './modules/sliderOferecemos';
import stepsForms from './modules/stepsForms';
import feedback from './modules/feedback';

parallaxImage();
animation();
mobileMenu();
placeholderForm();
oqueOferecemos();
targetScroll();
scrollTop();
sliderOferecemos();
stepsForms();
feedback();
