'use strict'

export default function () {

  const sectionPage = document.querySelectorAll('.js-animation')
  const windowMetade = window.innerHeight * 0.6

  function animaScroll() {
    sectionPage.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top
      const isSectionVisible = (sectionTop - windowMetade) < 0
      if (isSectionVisible) {
        section.classList.add('ativo')
      }
    })
  }

  animaScroll()

  window.addEventListener('scroll', animaScroll)

}