'use strict';

export default function () {
  const boxOferecemos = document.querySelectorAll('.o-que-oferecemos--item')
  boxOferecemos.forEach((item) => {
    item.addEventListener('click', () => {
      boxOferecemos.forEach(box => box.classList.remove('active'))
      item.classList.add('active')
    })
  })
}