'use strict'
import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider'

export default function () {
  const slider = tns({
    container: '.o-que-oferecemos_slider',
    items: 1,
    controls: false,
    nav: true,
    center: true,
    mouseDrag: true,
    touch: true,
    navPosition: 'bottom',
  })
}