'use strict'



export default function () {
  function scrollToSetion(event) {
    event.preventDefault()
    const href = event.currentTarget.getAttribute('href')
    const section = document.querySelector(href)

    const topo = section.offsetTop;
    window.scrollTo({
      top: topo,
      behavior: 'smooth'
    })
  }
  const menus = document.querySelectorAll('.menu-principal-container .menu-item a')
  menus.forEach((menu) => { menu.addEventListener('click', scrollToSetion) })
  const buttons = document.querySelectorAll('.button-target')
  buttons.forEach(button => button.addEventListener('click', scrollToSetion))
}