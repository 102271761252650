'use strict'

export default function () {

  const arrowTop = document.querySelector('.scroll-top')
  const altura = document.querySelector('.parallax-container')

  window.addEventListener('scroll', () => {
    if (window.scrollY > (altura.clientHeight - 200)) {
      arrowTop.style.opacity = 1
    } else {
      arrowTop.style.opacity = 0
    }
  })

  arrowTop.addEventListener('click', (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })

}