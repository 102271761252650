'use strict';

export default function () {

  const imageSelect = document.querySelector('.hero-banner--image')
  const heroBanner = document.querySelector('.parallax-container')
  const headerHeight = document.querySelector('.header')
  let heightParallax = heroBanner.clientHeight - headerHeight.clientHeight
  imageSelect.style.height = heightParallax + 'px';
  if (window.innerWidth <= 1024) {
    imageSelect.style.height = 'auto';
  }
  window.addEventListener('resize', () => {
    heightParallax = heroBanner.clientHeight - 92
    imageSelect.style.height = (heroBanner.clientHeight - 92) + 'px';
    if (window.innerWidth <= 1024) {
      imageSelect.style.height = 'auto';
    }
  })

}