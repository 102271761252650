'use strict'

export default function () {
  const feedback = document.querySelector('.feedback')
  const openFeedback = document.querySelector('.open-feedback')
  const wpcf7Elm = document.querySelectorAll('.wpcf7');


  if (window.localStorage.getItem("feedback") == 'sim') {
    openFeedback.classList.add('feedback-respondido')
    openFeedback.classList.remove('open-feedback')
    feedback.classList.add('close')
  }

  // openFeedback.addEventListener('click', () => {
  //   if (feedback.classList.contains('close')) {
  //     feedback.classList.add('ativo')
  //     feedback.classList.remove('close')
  //   } else {
  //     feedback.classList.remove('ativo')
  //     feedback.classList.add('close')
  //   }
  // })

  wpcf7Elm.forEach((form) => {

    form.addEventListener('wpcf7mailsent', function (event) {
      if (event.detail.contactFormId == '67') {
        window.localStorage.setItem("feedback", "sim");
        feedback.classList.remove('ativo')
        feedback.classList.add('close')
        openFeedback.classList.remove('open-feedback')
        openFeedback.classList.add('feedback-respondido')
      }
    }, false);
  })

}